/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiMasterDataIncotermActiveGet } from '../fn/master-incoterm/api-master-data-incoterm-active-get';
import { ApiMasterDataIncotermActiveGet$Params } from '../fn/master-incoterm/api-master-data-incoterm-active-get';
import { apiMasterDataIncotermActiveInModelMasterSimpleKeyValueItemGet } from '../fn/master-incoterm/api-master-data-incoterm-active-in-model-master-simple-key-value-item-get';
import { ApiMasterDataIncotermActiveInModelMasterSimpleKeyValueItemGet$Params } from '../fn/master-incoterm/api-master-data-incoterm-active-in-model-master-simple-key-value-item-get';
import { apiMasterDataIncotermAddPost } from '../fn/master-incoterm/api-master-data-incoterm-add-post';
import { ApiMasterDataIncotermAddPost$Params } from '../fn/master-incoterm/api-master-data-incoterm-add-post';
import { apiMasterDataIncotermAllGet } from '../fn/master-incoterm/api-master-data-incoterm-all-get';
import { ApiMasterDataIncotermAllGet$Params } from '../fn/master-incoterm/api-master-data-incoterm-all-get';
import { apiMasterDataIncotermDeleteGet } from '../fn/master-incoterm/api-master-data-incoterm-delete-get';
import { ApiMasterDataIncotermDeleteGet$Params } from '../fn/master-incoterm/api-master-data-incoterm-delete-get';
import { apiMasterDataIncotermEditPost } from '../fn/master-incoterm/api-master-data-incoterm-edit-post';
import { ApiMasterDataIncotermEditPost$Params } from '../fn/master-incoterm/api-master-data-incoterm-edit-post';
import { apiMasterDataIncotermMaintainGet } from '../fn/master-incoterm/api-master-data-incoterm-maintain-get';
import { ApiMasterDataIncotermMaintainGet$Params } from '../fn/master-incoterm/api-master-data-incoterm-maintain-get';
import { MasterIncotermItem } from '../models/master-incoterm-item';
import { MasterSimpleKeyValueItem } from '../models/master-simple-key-value-item';

@Injectable({ providedIn: 'root' })
export class MasterIncotermAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMasterDataIncotermActiveGet()` */
  static readonly ApiMasterDataIncotermActiveGetPath = '/api/MasterData/Incoterm/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataIncotermActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataIncotermActiveGet$Response(params?: ApiMasterDataIncotermActiveGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterIncotermItem>>> {
    return apiMasterDataIncotermActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataIncotermActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataIncotermActiveGet(params?: ApiMasterDataIncotermActiveGet$Params, context?: HttpContext): Observable<Array<MasterIncotermItem>> {
    return this.apiMasterDataIncotermActiveGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterIncotermItem>>): Array<MasterIncotermItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataIncotermMaintainGet()` */
  static readonly ApiMasterDataIncotermMaintainGetPath = '/api/MasterData/Incoterm/Maintain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataIncotermMaintainGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataIncotermMaintainGet$Response(params?: ApiMasterDataIncotermMaintainGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterIncotermItem>>> {
    return apiMasterDataIncotermMaintainGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataIncotermMaintainGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataIncotermMaintainGet(params?: ApiMasterDataIncotermMaintainGet$Params, context?: HttpContext): Observable<Array<MasterIncotermItem>> {
    return this.apiMasterDataIncotermMaintainGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterIncotermItem>>): Array<MasterIncotermItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataIncotermAllGet()` */
  static readonly ApiMasterDataIncotermAllGetPath = '/api/MasterData/Incoterm/All';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataIncotermAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataIncotermAllGet$Response(params?: ApiMasterDataIncotermAllGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterIncotermItem>>> {
    return apiMasterDataIncotermAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataIncotermAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataIncotermAllGet(params?: ApiMasterDataIncotermAllGet$Params, context?: HttpContext): Observable<Array<MasterIncotermItem>> {
    return this.apiMasterDataIncotermAllGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterIncotermItem>>): Array<MasterIncotermItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataIncotermActiveInModelMasterSimpleKeyValueItemGet()` */
  static readonly ApiMasterDataIncotermActiveInModelMasterSimpleKeyValueItemGetPath = '/api/MasterData/Incoterm/ActiveInModelMasterSimpleKeyValueItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataIncotermActiveInModelMasterSimpleKeyValueItemGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataIncotermActiveInModelMasterSimpleKeyValueItemGet$Response(params?: ApiMasterDataIncotermActiveInModelMasterSimpleKeyValueItemGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<MasterSimpleKeyValueItem>>> {
    return apiMasterDataIncotermActiveInModelMasterSimpleKeyValueItemGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataIncotermActiveInModelMasterSimpleKeyValueItemGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataIncotermActiveInModelMasterSimpleKeyValueItemGet(params?: ApiMasterDataIncotermActiveInModelMasterSimpleKeyValueItemGet$Params, context?: HttpContext): Observable<Array<MasterSimpleKeyValueItem>> {
    return this.apiMasterDataIncotermActiveInModelMasterSimpleKeyValueItemGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<MasterSimpleKeyValueItem>>): Array<MasterSimpleKeyValueItem> => r.body)
    );
  }

  /** Path part for operation `apiMasterDataIncotermAddPost()` */
  static readonly ApiMasterDataIncotermAddPostPath = '/api/MasterData/Incoterm/Add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataIncotermAddPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataIncotermAddPost$Response(params?: ApiMasterDataIncotermAddPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataIncotermAddPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataIncotermAddPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataIncotermAddPost(params?: ApiMasterDataIncotermAddPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataIncotermAddPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataIncotermEditPost()` */
  static readonly ApiMasterDataIncotermEditPostPath = '/api/MasterData/Incoterm/Edit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataIncotermEditPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataIncotermEditPost$Response(params?: ApiMasterDataIncotermEditPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataIncotermEditPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataIncotermEditPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiMasterDataIncotermEditPost(params?: ApiMasterDataIncotermEditPost$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataIncotermEditPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiMasterDataIncotermDeleteGet()` */
  static readonly ApiMasterDataIncotermDeleteGetPath = '/api/MasterData/Incoterm/Delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterDataIncotermDeleteGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataIncotermDeleteGet$Response(params?: ApiMasterDataIncotermDeleteGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiMasterDataIncotermDeleteGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMasterDataIncotermDeleteGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterDataIncotermDeleteGet(params?: ApiMasterDataIncotermDeleteGet$Params, context?: HttpContext): Observable<void> {
    return this.apiMasterDataIncotermDeleteGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
