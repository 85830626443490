/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiJobCreateRenewalAmendmentRequestGet } from '../fn/job/api-job-create-renewal-amendment-request-get';
import { ApiJobCreateRenewalAmendmentRequestGet$Params } from '../fn/job/api-job-create-renewal-amendment-request-get';
import { apiJobJob01SyncDataFromMdmGet } from '../fn/job/api-job-job-01-sync-data-from-mdm-get';
import { ApiJobJob01SyncDataFromMdmGet$Params } from '../fn/job/api-job-job-01-sync-data-from-mdm-get';
import { apiJobJob02SyncMasterDataFromDwGet } from '../fn/job/api-job-job-02-sync-master-data-from-dw-get';
import { ApiJobJob02SyncMasterDataFromDwGet$Params } from '../fn/job/api-job-job-02-sync-master-data-from-dw-get';
import { apiJobJob04MarkFinishToDailyInfoItemGet } from '../fn/job/api-job-job-04-mark-finish-to-daily-info-item-get';
import { ApiJobJob04MarkFinishToDailyInfoItemGet$Params } from '../fn/job/api-job-job-04-mark-finish-to-daily-info-item-get';
import { apiJobJob05SyncKeywordForDailyInfoItemGet } from '../fn/job/api-job-job-05-sync-keyword-for-daily-info-item-get';
import { ApiJobJob05SyncKeywordForDailyInfoItemGet$Params } from '../fn/job/api-job-job-05-sync-keyword-for-daily-info-item-get';
import { apiJobJob06ChangePurGroupFromSapGet } from '../fn/job/api-job-job-06-change-pur-group-from-sap-get';
import { ApiJobJob06ChangePurGroupFromSapGet$Params } from '../fn/job/api-job-job-06-change-pur-group-from-sap-get';
import { apiJobJob07GetCognitiveStockResultGet } from '../fn/job/api-job-job-07-get-cognitive-stock-result-get';
import { ApiJobJob07GetCognitiveStockResultGet$Params } from '../fn/job/api-job-job-07-get-cognitive-stock-result-get';
import { apiJobJob08MatchingWithVendorGet } from '../fn/job/api-job-job-08-matching-with-vendor-get';
import { ApiJobJob08MatchingWithVendorGet$Params } from '../fn/job/api-job-job-08-matching-with-vendor-get';
import { apiJobJob09AutoChangePurGroupToSapGet } from '../fn/job/api-job-job-09-auto-change-pur-group-to-sap-get';
import { ApiJobJob09AutoChangePurGroupToSapGet$Params } from '../fn/job/api-job-job-09-auto-change-pur-group-to-sap-get';
import { apiJobJob10AutoCreatePoToSapGet } from '../fn/job/api-job-job-10-auto-create-po-to-sap-get';
import { ApiJobJob10AutoCreatePoToSapGet$Params } from '../fn/job/api-job-job-10-auto-create-po-to-sap-get';
import { apiJobJob11SetupPostEvaluationDataForVeNusGet } from '../fn/job/api-job-job-11-setup-post-evaluation-data-for-ve-nus-get';
import { ApiJobJob11SetupPostEvaluationDataForVeNusGet$Params } from '../fn/job/api-job-job-11-setup-post-evaluation-data-for-ve-nus-get';
import { apiJobJob12SyncMasterDataFromVeNusGet } from '../fn/job/api-job-job-12-sync-master-data-from-ve-nus-get';
import { ApiJobJob12SyncMasterDataFromVeNusGet$Params } from '../fn/job/api-job-job-12-sync-master-data-from-ve-nus-get';
import { apiJobJob13FlipSuggestContractToPoGet } from '../fn/job/api-job-job-13-flip-suggest-contract-to-po-get';
import { ApiJobJob13FlipSuggestContractToPoGet$Params } from '../fn/job/api-job-job-13-flip-suggest-contract-to-po-get';
import { apiJobJob15ClearSystemLogsGet } from '../fn/job/api-job-job-15-clear-system-logs-get';
import { ApiJobJob15ClearSystemLogsGet$Params } from '../fn/job/api-job-job-15-clear-system-logs-get';
import { apiJobJob3CreateDailyInfoItemGet } from '../fn/job/api-job-job-3-create-daily-info-item-get';
import { ApiJobJob3CreateDailyInfoItemGet$Params } from '../fn/job/api-job-job-3-create-daily-info-item-get';
import { apiJobNotifyPendingActionGet } from '../fn/job/api-job-notify-pending-action-get';
import { ApiJobNotifyPendingActionGet$Params } from '../fn/job/api-job-notify-pending-action-get';

@Injectable({ providedIn: 'root' })
export class JobAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiJobCreateRenewalAmendmentRequestGet()` */
  static readonly ApiJobCreateRenewalAmendmentRequestGetPath = '/api/Job/CreateRenewalAmendmentRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobCreateRenewalAmendmentRequestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCreateRenewalAmendmentRequestGet$Response(params?: ApiJobCreateRenewalAmendmentRequestGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobCreateRenewalAmendmentRequestGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobCreateRenewalAmendmentRequestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobCreateRenewalAmendmentRequestGet(params?: ApiJobCreateRenewalAmendmentRequestGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobCreateRenewalAmendmentRequestGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobNotifyPendingActionGet()` */
  static readonly ApiJobNotifyPendingActionGetPath = '/api/Job/NotifyPendingAction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobNotifyPendingActionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobNotifyPendingActionGet$Response(params?: ApiJobNotifyPendingActionGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobNotifyPendingActionGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobNotifyPendingActionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobNotifyPendingActionGet(params?: ApiJobNotifyPendingActionGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobNotifyPendingActionGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob01SyncDataFromMdmGet()` */
  static readonly ApiJobJob01SyncDataFromMdmGetPath = '/api/Job/job01_SyncDataFromMDM';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob01SyncDataFromMdmGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob01SyncDataFromMdmGet$Response(params?: ApiJobJob01SyncDataFromMdmGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob01SyncDataFromMdmGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob01SyncDataFromMdmGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob01SyncDataFromMdmGet(params?: ApiJobJob01SyncDataFromMdmGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob01SyncDataFromMdmGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob02SyncMasterDataFromDwGet()` */
  static readonly ApiJobJob02SyncMasterDataFromDwGetPath = '/api/Job/job02_SyncMasterDataFromDW';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob02SyncMasterDataFromDwGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob02SyncMasterDataFromDwGet$Response(params?: ApiJobJob02SyncMasterDataFromDwGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob02SyncMasterDataFromDwGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob02SyncMasterDataFromDwGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob02SyncMasterDataFromDwGet(params?: ApiJobJob02SyncMasterDataFromDwGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob02SyncMasterDataFromDwGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob3CreateDailyInfoItemGet()` */
  static readonly ApiJobJob3CreateDailyInfoItemGetPath = '/api/Job/job3_CreateDailyInfoItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob3CreateDailyInfoItemGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob3CreateDailyInfoItemGet$Response(params?: ApiJobJob3CreateDailyInfoItemGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob3CreateDailyInfoItemGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob3CreateDailyInfoItemGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob3CreateDailyInfoItemGet(params?: ApiJobJob3CreateDailyInfoItemGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob3CreateDailyInfoItemGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob04MarkFinishToDailyInfoItemGet()` */
  static readonly ApiJobJob04MarkFinishToDailyInfoItemGetPath = '/api/Job/job04_MarkFinishToDailyInfoItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob04MarkFinishToDailyInfoItemGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob04MarkFinishToDailyInfoItemGet$Response(params?: ApiJobJob04MarkFinishToDailyInfoItemGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob04MarkFinishToDailyInfoItemGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob04MarkFinishToDailyInfoItemGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob04MarkFinishToDailyInfoItemGet(params?: ApiJobJob04MarkFinishToDailyInfoItemGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob04MarkFinishToDailyInfoItemGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob05SyncKeywordForDailyInfoItemGet()` */
  static readonly ApiJobJob05SyncKeywordForDailyInfoItemGetPath = '/api/Job/job05_SyncKeywordForDailyInfoItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob05SyncKeywordForDailyInfoItemGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob05SyncKeywordForDailyInfoItemGet$Response(params?: ApiJobJob05SyncKeywordForDailyInfoItemGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob05SyncKeywordForDailyInfoItemGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob05SyncKeywordForDailyInfoItemGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob05SyncKeywordForDailyInfoItemGet(params?: ApiJobJob05SyncKeywordForDailyInfoItemGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob05SyncKeywordForDailyInfoItemGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob06ChangePurGroupFromSapGet()` */
  static readonly ApiJobJob06ChangePurGroupFromSapGetPath = '/api/Job/job06_ChangePurGroupFromSAP';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob06ChangePurGroupFromSapGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob06ChangePurGroupFromSapGet$Response(params?: ApiJobJob06ChangePurGroupFromSapGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob06ChangePurGroupFromSapGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob06ChangePurGroupFromSapGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob06ChangePurGroupFromSapGet(params?: ApiJobJob06ChangePurGroupFromSapGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob06ChangePurGroupFromSapGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob07GetCognitiveStockResultGet()` */
  static readonly ApiJobJob07GetCognitiveStockResultGetPath = '/api/Job/job07_GetCognitiveStockResult';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob07GetCognitiveStockResultGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob07GetCognitiveStockResultGet$Response(params?: ApiJobJob07GetCognitiveStockResultGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob07GetCognitiveStockResultGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob07GetCognitiveStockResultGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob07GetCognitiveStockResultGet(params?: ApiJobJob07GetCognitiveStockResultGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob07GetCognitiveStockResultGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob08MatchingWithVendorGet()` */
  static readonly ApiJobJob08MatchingWithVendorGetPath = '/api/Job/job08_MatchingWithVendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob08MatchingWithVendorGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob08MatchingWithVendorGet$Response(params?: ApiJobJob08MatchingWithVendorGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob08MatchingWithVendorGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob08MatchingWithVendorGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob08MatchingWithVendorGet(params?: ApiJobJob08MatchingWithVendorGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob08MatchingWithVendorGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob09AutoChangePurGroupToSapGet()` */
  static readonly ApiJobJob09AutoChangePurGroupToSapGetPath = '/api/Job/job09_AutoChangePurGroupToSAP';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob09AutoChangePurGroupToSapGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob09AutoChangePurGroupToSapGet$Response(params?: ApiJobJob09AutoChangePurGroupToSapGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob09AutoChangePurGroupToSapGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob09AutoChangePurGroupToSapGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob09AutoChangePurGroupToSapGet(params?: ApiJobJob09AutoChangePurGroupToSapGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob09AutoChangePurGroupToSapGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob10AutoCreatePoToSapGet()` */
  static readonly ApiJobJob10AutoCreatePoToSapGetPath = '/api/Job/job10_AutoCreatePOToSAP';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob10AutoCreatePoToSapGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob10AutoCreatePoToSapGet$Response(params?: ApiJobJob10AutoCreatePoToSapGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob10AutoCreatePoToSapGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob10AutoCreatePoToSapGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob10AutoCreatePoToSapGet(params?: ApiJobJob10AutoCreatePoToSapGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob10AutoCreatePoToSapGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob11SetupPostEvaluationDataForVeNusGet()` */
  static readonly ApiJobJob11SetupPostEvaluationDataForVeNusGetPath = '/api/Job/job11_SetupPostEvaluationDataForVeNus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob11SetupPostEvaluationDataForVeNusGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob11SetupPostEvaluationDataForVeNusGet$Response(params?: ApiJobJob11SetupPostEvaluationDataForVeNusGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob11SetupPostEvaluationDataForVeNusGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob11SetupPostEvaluationDataForVeNusGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob11SetupPostEvaluationDataForVeNusGet(params?: ApiJobJob11SetupPostEvaluationDataForVeNusGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob11SetupPostEvaluationDataForVeNusGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob12SyncMasterDataFromVeNusGet()` */
  static readonly ApiJobJob12SyncMasterDataFromVeNusGetPath = '/api/Job/job12_SyncMasterDataFromVeNus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob12SyncMasterDataFromVeNusGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob12SyncMasterDataFromVeNusGet$Response(params?: ApiJobJob12SyncMasterDataFromVeNusGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob12SyncMasterDataFromVeNusGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob12SyncMasterDataFromVeNusGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob12SyncMasterDataFromVeNusGet(params?: ApiJobJob12SyncMasterDataFromVeNusGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob12SyncMasterDataFromVeNusGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob13FlipSuggestContractToPoGet()` */
  static readonly ApiJobJob13FlipSuggestContractToPoGetPath = '/api/Job/job13_FlipSuggestContractToPO';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob13FlipSuggestContractToPoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob13FlipSuggestContractToPoGet$Response(params?: ApiJobJob13FlipSuggestContractToPoGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob13FlipSuggestContractToPoGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob13FlipSuggestContractToPoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob13FlipSuggestContractToPoGet(params?: ApiJobJob13FlipSuggestContractToPoGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob13FlipSuggestContractToPoGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiJobJob15ClearSystemLogsGet()` */
  static readonly ApiJobJob15ClearSystemLogsGetPath = '/api/Job/job15_ClearSystemLogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobJob15ClearSystemLogsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob15ClearSystemLogsGet$Response(params?: ApiJobJob15ClearSystemLogsGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<void>> {
    return apiJobJob15ClearSystemLogsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiJobJob15ClearSystemLogsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobJob15ClearSystemLogsGet(params?: ApiJobJob15ClearSystemLogsGet$Params, context?: HttpContext): Observable<void> {
    return this.apiJobJob15ClearSystemLogsGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<void>): void => r.body)
    );
  }

}
